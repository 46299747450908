// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
import 'scss/application'
import 'scss/sidebar'
import 'scss/table-grid-base'
import 'scss/table-grid-definitions'
import 'scss/immat'
import 'scss/welcome-page'
import 'scss/dispatch'
import 'scss/workshop-screen'
import "@fortawesome/fontawesome-free/css/all"

// Select 2
import 'select2/dist/css/select2.css' // css // theme
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css' // css // theme
import 'select2' // Main JS
require('select2/dist/js/i18n/fr'); // Locale

require("@nathanvda/cocoon")

Rails.start()
ActiveStorage.start()

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    // If you edit this line, don't forget to edit this line in all file
    // because of Cocoon some select2() is in the js functions
    $('.select2').select2({ language: 'fr', theme: 'bootstrap-5' })
})
